export interface IRestaurantViewProps {
    name: string;
    address: string;
    phone: string;
    phone2: string;
    link: string;
}

const RestaurantBlock: React.FC<IRestaurantViewProps> = (props) => {
    const handleClick = () => {
        window.open(props.link);
    };

    return (
        <div onClick={handleClick} className="restaurant-block">
            <div className="restaurant-block__title">{props.name}</div>
            <div className="restaurant-block__text">{props.address}</div>
            <div className="restaurant-block__text">
                <a
                    onClick={(e) => e.stopPropagation()}
                    href={`tel:${props.phone}`}
                    className="restaurant-block__text"
                >
                    {props.phone}
                </a>
            </div>
            <div className="restaurant-block__text">
                <a
                    onClick={(e) => e.stopPropagation()}
                    href={`tel:${props.phone2}`}
                    className="restaurant-block__text"
                >
                    {props.phone2}
                </a>
            </div>
        </div>
    );
};

export default RestaurantBlock;
