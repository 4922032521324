export interface IHeaderBlockViewProps {}

//ios link https://apps.apple.com/us/app/meal-box/id1559727816
//android link https://play.google.com/store/apps/details?id=com.oneminorder.mealbox

const HeaderBlock: React.FC<IHeaderBlockViewProps> = (props) => {
    const handleClickAndroid = () => {
        window.open("https://play.google.com/store/apps/details?id=com.oneminorder.mealbox");
    };

    const handleClickiOS = () => {
        window.open("https://apps.apple.com/us/app/meal-box/id1559727816");
    };

    return (
        <div className="header-block">
            <div className="global__space-after"></div>
            <div className="header-block__logo"></div>
            <div className="header-block__mx-logo"></div>
            <div className="header-block__button-container">
                <div
                    onClick={handleClickAndroid}
                    className="header-block__button-container--image android"
                ></div>
            </div>
            <div className="header-block__button-container">
                <div
                    onClick={handleClickiOS}
                    className="header-block__button-container--image ios"
                ></div>
            </div>
        </div>
    );
};

export default HeaderBlock;
