import HeaderBlock from "../../HeaderBlock/View/HeaderBlock.view";
import RestaurantBlock from "../../RestaurantBlock/View/RestaurantBlock.view";

export interface IMainPageViewProps {}

const MainPageView: React.FC<IMainPageViewProps> = (props) => {
    return (
        <div className="main-page">
            <header className="main-page-header">
                <HeaderBlock></HeaderBlock>
            </header>
            <footer className="main-page-footer">
                <RestaurantBlock
                    name="Gyöngyös"
                    address="Vásár utca 2. (Tesco)"
                    phone="30/325-3256"
                    phone2="30/263-9886"
                    link="https://gyongyos.mealbox.hu"
                />

                <RestaurantBlock
                    name="Hatvan"
                    address="Kerekharaszt M3, 3009"
                    phone="30/325-3256"
                    phone2="30/263-9886"
                    link="https://hatvan.mealbox.hu"
                />

                <RestaurantBlock
                    name="Füzesabony"
                    address="Baross Gábor út, 8."
                    phone="30/325-3256"
                    phone2="30/263-9886"
                    link="https://fuzesabony.mealbox.hu/"
                />

                <RestaurantBlock
                    name="Mezőkövesd"
                    address="Egri ut 51. (Tesco)"
                    phone="30/325-3256"
                    phone2="30/263-9886"
                    link="https://mezokovesd.mealbox.hu/"
                />
            </footer>
        </div>
    );
};

export default MainPageView;
